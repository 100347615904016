export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri Kaydet"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydediliyor..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin misiniz?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Kopyala"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bugün ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dün ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kanalda konuşma yetkiniz yok."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı Değil"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Ekle"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajını Yaz"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günümüze Işınlan"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Mesajlar"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıkıştır"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şuraya Yükle:"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profili Görüntüle"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolleri Düzenle"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yasakla"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcının Etiketini Kopyala"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıntı"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okundu Olarak İşaretle"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Ayarları"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucudan Ayrıl"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalı Sustur"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susturmayı Kaldır"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojileri Yönet"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç Ayarları"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Etkinliği"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botları Yönet"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arayüz"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı Sil"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişim Kaydı"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapım Aşamasındaki Özellikler"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilini Düzenle"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi Değiştir"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDrive'ı Yeniden Bağla"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDrive'ı Bağla"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Profile"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bilgi herkes tarafından görüntülenir."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim ayarlarını değiştir."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Sesi"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkatinizi çekmek için ilginç sesler çıkarır."])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlık Bildirimler"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama kapatılsa bile anlık bildirim almaya devam edin."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O harika emojilerini ücret ödemeden yükle! Unutmadan, dosyalar 3MB üstüne çıkamaz."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desteklenen dosya türleri şunlardır: .png, .jpg, .gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji Ekle"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masaüstü Uygulaması Başlatma Seçenekleri"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarları değiştirmek için Nertivia'yı bilgisayarınıza indirmeniz gereklidir."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgisayar Başladığında Nertivia da Açılsın."])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıya uygulamalar ve oyunlar ekleyerek, insanları ne yaptığınızdan haberdar edebilirsiniz. Bu yaptığınız işlem özel durumunuz yerine geçecek ve siz programdan çıktıktan sonra eski haline dönecektir."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Etkinliği özelliğini kullanmak için bilgisayarınıza Nertivia yazılımını indirmelisiniz."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi muhteşem botlarınızı yazmak için API'mizi kullanmanıza yarayacak bot kullanıcılar oluşturun."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılabilir komutları kullanıcılara bildirmek için bot komutlarınızı ekleyin."])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Bot Oluştur"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komut Ekle"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botu Düzenle"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komutu Düzenle"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet Linki Oluştur"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet Bağlantısını Kopyala"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeni Yönet"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeni Göster"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeni Kopyala"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeni Yenile"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botu Sil"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanda belirtilen hesabı silmek üzeresiniz:"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neler silinecek?"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neler Silinmeyecek?"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık sunucularda gönderdiğiniz mesajlar silinmeyecektir."])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gereksinimler"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün Sunuculardan Çıkış Yap/Sil"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün botları sil"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabımı Sil"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dili değiştirdikten sonra sayfayı yeniden yüklemeniz gerekebilir. GitHub üzerinden dil desteğinin gelişmesine yardımcı olabilirsiniz."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevirmenler"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nertivia'nın görünümünü değiştir."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renkler"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat Biçimi"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan Tema"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Siyahı"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadılar Bayramı 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öntanımlı Temalar"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel CSS"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezinti Çubuğu"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanmamış veya deneysel özellikleri buradan etkinleştirebilirsiniz."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu anda kullanılabilir özellik bulunmuyor."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzinler"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetici"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm yetkileri kullanmasına izin verir."])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Gönder"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucudaki kanallara mesaj gönder."])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolleri Yönet"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolleri güncelle veya kaldır."])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalları Yönet"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalları güncelle veya kaldır."])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı At"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yasakla"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı at ve buraya katılmasını engelle."])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalları Yönet"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolleri Yönet"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davetleri Yönet"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Görünürlüğü"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimleri Yönet"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yasaklanan Kullanıcılar"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları Yönet"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucuyu Sil"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolleri Yönet."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan Rol"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Rolü"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Rol Oluştur"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu rol bir bot tarafından oluşturulduğu için silinemez ya da başkasına verilemez. Bu rolü silmek için bağlantılı botu kaldırın."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu rol, katılan her üyeye verilen varsayılan rol olduğu için silinemez ya da geri alınamaz."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol Rengini Seç"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol Adı"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolü Sil"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol Siliniyor..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolü Gizle"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Genel Ayarları"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucundaki kanalları buradan yönetebilirsin."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalı Sil"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal Siliniyor..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal Adı"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kanal varsayılan olarak seçilmiştir. Genel Sunucu Ayarları üzerinden varsayılan kanalı değiştirebilirsiniz. Varsayılan kanalları silemezsiniz."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kanal Oluştur"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Gönder"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Hızı Sınırı (saniyede)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin Kanalı"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML Kanalı (HGA)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buradan yasaklamış olduğunuz üyelerin yasaklarını kaldırabilirsiniz."])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yasak Kaldırılıyor..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yasak Kaldır"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece yöneticiler bütün davetleri görebilir."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan Davetler"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Davet Bağlantısı"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Davet Bağlantısını Kaydet"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet Oluştur"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturan"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Sayısı"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Ara"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemi onaylamak için sunucunun adını yazın."])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siliniyor..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim ayarlarını buradan değiştirebilirsiniz."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler yalnızca sende uygulanır, diğer kullanıcılar bundan etkilenmez."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimleri Etkinleştir"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi Kapat"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimleri Devre Dışı Bırak"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " yazıyor..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ve ", _interpolate(_list(1)), " yazıyor..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " ve ", _interpolate(_list(2)), " yazıyor..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Birçok kişi yazıyor..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaşlar"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son Gelişmeler"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilen Notlar"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaş Ekle"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel durum eklemek için tıkla."])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pek bir değişiklik yok."])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Mesajlar"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucular"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keşfet"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol Paneli"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı adını ve etiketini yazarak bir arkadaş ekleyin. (Örneğin Nertivia:1234)"])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekleniyor..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Ekle"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir sunucuya katılmak için o sunucuya ait bir davet girin."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendinize ait bir sunucu oluşturun. Pekala, önce isim ile başlayalım:"])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katıl"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluştur"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimdışı"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimiçi"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzakta"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meşgul"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oyuncu 2 Bekleniyor"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Üyeler (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Çevrimdışı (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortak Arkadaşlar"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozetler"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu Tarihte Katıldı:"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Askıya Alındı"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaşlıktan Çıkar"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteği Kabul Et"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteği İptal Et"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaş Olarak Ekle"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Gönder"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortak Sunucular"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkilendiriliyor..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme Kullanılabilir!"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyala"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyalandı!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunucudan ayrıldı."])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atıldı!"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yasaklandı!"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunucuya katıldı!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu ile bağlantı kurulamadı."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Hız Sınırı Modu Açık."])}
  }
}